// ########################## [IMPORTANT LIBRARIES]
import { FC, memo, useMemo, useRef } from 'react';
import classnames from 'classnames';

// ########################## [TYPES]
import { IPageWithTabs } from '@ds-web/components/templates/with-tabs/types';

// ########################## [PAGE COMPONENTS]
import SectionWithTopTabsListing from './tabs-navigation/SectionWithTopTabsListing';
import BodyContent from '@ds-web/components/templates/with-tabs/components/body/BodyContent';
import PreBodyContent from '@ds-web/components/templates/with-tabs/components/body/PreBodyContent';

// ########################## [HOOKS]
import { useGetDeviceSize } from '@ds-web/utils/hooks';

interface ISectionWithTopTabsNavigatorProps
  extends Omit<IPageWithTabs, 'headingTitle'> {}

/**
 * [LAYOUT SECTION WITH TOP TABS - CARD] This component is used to render the tabs navigator of the layout card.
 * It holds the actual tabs listings and their respective content.
 * N.B. The content shown is based on the currently active tab and NO OTHER page is pre-rendered and hidden in the parsed HTML code.
 *
 * @interface ISectionWithTopTabsNavigatorProps
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const SectionWithTopTabsNavigator: FC<ISectionWithTopTabsNavigatorProps> =
  memo(({ tabs, activeTabIndex, toggle, loadInvisibleTabs, rightmostCTA }) => {
    const { isTablet, isMobile } = useGetDeviceSize();

    const activeTabRef = useRef<HTMLDivElement>(null);

    // Selecting the currently displayed tab based on the active "Tab Index"
    const activeTab = useMemo(
      () => tabs[activeTabIndex] ?? tabs[0],
      [activeTabIndex, tabs],
    );

    return (
      <div
        className={classnames('!w-full flex flex-col flex-nowrap h-full p-0', {
          'container-override': isTablet || isMobile,
        })}
      >
        <section className="flex flex-[1_0_auto] flex-wrap justify-between items-end">
          <SectionWithTopTabsListing
            activeTabRef={activeTabRef}
            tabs={tabs}
            activeTabIndex={activeTabIndex}
            toggle={toggle}
          />
        </section>
        <div className="wrapper-tab-content h-full flex flex-col justify-stretch">
          <PreBodyContent currentTab={activeTab} />
          <BodyContent
            tabs={tabs}
            activeTabIndex={activeTabIndex}
            loadInvisibleTabs={loadInvisibleTabs}
          />
        </div>
      </div>
    );
  });

SectionWithTopTabsNavigator.displayName =
  'SectionWithTopTabsNavigator_memoized';
