export type LinkTranslations = {
  en: string;
  it: string;
  de: string;
  fr: string;
  es: string;
  pt: string;
  pl: string;
};

export const localeLinks: Record<string, LinkTranslations> = {
  languageSlugs: {
    en: 'en/',
    it: 'it/',
    de: 'de/',
    fr: 'fr/',
    es: 'es/',
    pt: 'pt/',
    pl: 'pl/',
  },
  carrierComparison: {
    en: 'en/carriers-comparison-map/',
    it: 'it/mappa-comparazione-corrieri/',
    de: 'de/vergleichskarte-spediteure/',
    fr: 'fr/carte-de-comparaison-transporteurs/',
    es: 'es/mapa-comparativos-transportista/',
    pt: 'pt/mapa-compara-transportadoras/',
    pl: 'pl/mapa-porownywarka-przewoznikow/',
  },
  contactUs: {
    en: 'en/contact-us/',
    it: 'it/contatti/',
    de: 'de/kontakt/',
    fr: 'fr/contactez-nous/',
    es: 'es/contacto/',
    pt: 'pt/contacto/',
    pl: 'pl/kontakt/',
  },
  setupOrders: {
    it: 'LN5-i30Fn3M?start=16',
    en: 'PwkY9CaULtE?start=20',
    de: 'PwkY9CaULtE?start=20',
    fr: 'PwkY9CaULtE?start=20',
    es: 'PwkY9CaULtE?start=20',
    pt: 'PwkY9CaULtE?start=20',
    pl: 'PwkY9CaULtE?start=20',
  },
  setupCarriers: {
    it: 'LN5-i30Fn3M?start=94',
    en: 'PwkY9CaULtE?start=85',
    de: 'PwkY9CaULtE?start=85',
    fr: 'PwkY9CaULtE?start=85',
    es: 'PwkY9CaULtE?start=85',
    pt: 'PwkY9CaULtE?start=85',
    pl: 'PwkY9CaULtE?start=85',
  },
  setupPrinters: {
    it: 'LN5-i30Fn3M?start=198',
    en: 'PwkY9CaULtE?start=175',
    de: 'PwkY9CaULtE?start=175',
    fr: 'PwkY9CaULtE?start=175',
    es: 'PwkY9CaULtE?start=175',
    pt: 'PwkY9CaULtE?start=175',
    pl: 'PwkY9CaULtE?start=175',
  },
  deliverSurvey: {
    it: 'https://survey.refiner.io/ejlqnd-eozq52',
    en: 'https://survey.refiner.io/ejlqnd-eozq52',
    de: 'https://survey.refiner.io/ejlqnd-eozq52',
    fr: 'https://survey.refiner.io/ejlqnd-eozq52',
    es: 'https://survey.refiner.io/ejlqnd-eozq52',
    pt: 'https://survey.refiner.io/ejlqnd-eozq52',
    pl: 'https://survey.refiner.io/ejlqnd-eozq52',
  },
  importOrders: {
    it: 'https://help.shippypro.com/it/articles/8837134-new-experience-come-importare-gli-ordini-tramite-excel-o-csv',
    en: 'https://help.shippypro.com/en/articles/8837134-new-experience-how-to-import-your-orders-via-excel-or-csv',
    de: 'https://help.shippypro.com/en/articles/8837134-new-experience-how-to-import-your-orders-via-excel-or-csv',
    fr: 'https://help.shippypro.com/fr/articles/3278315-comment-importer-des-fichiers-excel-csv-ou-txt-avec-les-donnees-de-vos-envois',
    es: 'https://help.shippypro.com/es/articles/3278315-como-importar-un-archivo-excel-csv-o-txt-con-la-informacion-de-tus-pedidos',
    pt: 'https://help.shippypro.com/en/articles/8837134-new-experience-how-to-import-your-orders-via-excel-or-csv',
    pl: 'https://help.shippypro.com/en/articles/8837134-new-experience-how-to-import-your-orders-via-excel-or-csv',
  },
  filterOrders: {
    it: 'https://help.shippypro.com/it/articles/8870469-nuova-esperienza-come-filtrare-i-tuoi-ordini-dalla-sezione-da-spedire',
    en: 'https://help.shippypro.com/en/articles/8870469-new-experience-how-to-filter-your-orders-from-to-ship-section',
    de: 'https://help.shippypro.com/en/articles/8870469-new-experience-how-to-filter-your-orders-from-to-ship-section',
    fr: 'https://help.shippypro.com/en/articles/8870469-new-experience-how-to-filter-your-orders-from-to-ship-section',
    es: 'https://help.shippypro.com/en/articles/8870469-new-experience-how-to-filter-your-orders-from-to-ship-section',
    pt: 'https://help.shippypro.com/en/articles/8870469-new-experience-how-to-filter-your-orders-from-to-ship-section',
    pl: 'https://help.shippypro.com/en/articles/8870469-new-experience-how-to-filter-your-orders-from-to-ship-section',
  },
  archiveOrders: {
    it: 'https://help.shippypro.com/it/articles/8879262-nuova-esperienza-come-archiviare-e-disarchiviare-i-tuoi-ordini',
    en: 'https://help.shippypro.com/en/articles/8879262-new-experience-how-to-archive-and-unarchive-your-orders',
    de: 'https://help.shippypro.com/en/articles/8879262-new-experience-how-to-archive-and-unarchive-your-orders',
    fr: 'https://help.shippypro.com/en/articles/8879262-new-experience-how-to-archive-and-unarchive-your-orders',
    es: 'https://help.shippypro.com/en/articles/8879262-new-experience-how-to-archive-and-unarchive-your-orders',
    pt: 'https://help.shippypro.com/en/articles/8879262-new-experience-how-to-archive-and-unarchive-your-orders',
    pl: 'https://help.shippypro.com/en/articles/8879262-new-experience-how-to-archive-and-unarchive-your-orders',
  },
  deleteOrders: {
    it: 'https://help.shippypro.com/it/articles/3958088-come-gestire-la-cancellazione-di-ordini-e-spedizioni',
    en: 'https://help.shippypro.com/en/articles/3958088-how-to-manage-orders-and-shipments-cancellation',
    de: 'https://help.shippypro.com/en/articles/3958088-how-to-manage-orders-and-shipments-cancellation',
    fr: 'https://help.shippypro.com/fr/articles/3958088-comment-gerer-l-annulation-des-commandes-et-des-envois',
    es: 'https://help.shippypro.com/es/articles/3958088-como-gestionar-la-cancelacion-de-pedidos-y-envios',
    pt: 'https://help.shippypro.com/en/articles/3958088-how-to-manage-orders-and-shipments-cancellation',
    pl: 'https://help.shippypro.com/en/articles/3958088-how-to-manage-orders-and-shipments-cancellation',
  },
  createManifest: {
    it: 'https://help.shippypro.com/it/knowledge/nuova-esperienza-come-creare-un-borderò-o-manifest',
    en: 'https://help.shippypro.com/en/knowledge/articles/8867920-new-experience-how-to-create-a-manifest-or-borderau',
    de: 'https://help.shippypro.com/en/knowledge/articles/8867920-new-experience-how-to-create-a-manifest-or-borderau',
    fr: 'https://help.shippypro.com/fr/knowledge/nouvelle-experience-comment-creer-un-manifeste-ou-borderau',
    es: 'https://help.shippypro.com/es/knowledge/nueva-experiencia-como-crear-un-manifiesto-de-carga-o-sobordo',
    pt: 'https://help.shippypro.com/en/knowledge/articles/8867920-new-experience-how-to-create-a-manifest-or-borderau',
    pl: 'https://help.shippypro.com/en/knowledge/articles/8867920-new-experience-how-to-create-a-manifest-or-borderau',
  },
  createPickingList: {
    it: 'https://help.shippypro.com/it/knowledge/nuova-esperienza-come-creare-una-picking-list',
    en: 'https://help.shippypro.com/en/knowledge/articles/8878773-new-experience-how-to-create-a-picking-list',
    de: 'https://help.shippypro.com/en/knowledge/articles/8878773-new-experience-how-to-create-a-picking-list',
    fr: 'https://help.shippypro.com/fr/knowledge/nouvelle-exp%C3%A9rience-comment-cr%C3%A9er-une-liste-de-s%C3%A9lection',
    es: 'https://help.shippypro.com/es/knowledge/nueva-experiencia-como-crear-una-lista-de-picking',
    pt: 'https://help.shippypro.com/en/knowledge/articles/8878773-new-experience-how-to-create-a-picking-list',
    pl: 'https://help.shippypro.com/en/knowledge/articles/8878773-new-experience-how-to-create-a-picking-list',
  },
  manageEtdDocuments: {
    it: 'https://help.shippypro.com/it/knowledge/come-caricare-un-documento-etd',
    en: 'https://help.shippypro.com/en/knowledge/articles/3515574-how-to-manage-your-etd-documents',
    de: 'https://help.shippypro.com/en/knowledge/articles/3515574-how-to-manage-your-etd-documents',
    fr: 'https://help.shippypro.com/fr/knowledge/comment-gerer-vos-documents-etd',
    es: 'https://help.shippypro.com/en/knowledge/articles/3515574-how-to-manage-your-etd-documents',
    pt: 'https://help.shippypro.com/en/knowledge/articles/3515574-how-to-manage-your-etd-documents',
    pl: 'https://help.shippypro.com/en/knowledge/articles/3515574-how-to-manage-your-etd-documents',
  },
  createCustomsDocument: {
    it: 'https://help.shippypro.com/it/knowledge/nuova-esperienza-come-gestire-le-spedizioni-internazionali',
    en: 'https://help.shippypro.com/en/knowledge/articles/8765653-new-experience-how-to-manage-international-shipments',
    de: 'https://help.shippypro.com/en/knowledge/articles/8765653-new-experience-how-to-manage-international-shipments',
    fr: 'https://help.shippypro.com/fr/knowledge/nouvelle-exp%C3%A9rience-comment-g%C3%A9rer-les-envois-internationaux',
    es: 'https://help.shippypro.com/es/knowledge/nueva-experiencia-como-gestionar-los-envios-internacionales',
    pt: 'https://help.shippypro.com/en/knowledge/articles/8765653-new-experience-how-to-manage-international-shipments',
    pl: 'https://help.shippypro.com/en/knowledge/articles/8765653-new-experience-how-to-manage-international-shipments',
  },
  reactiveGlobalSearchHelp: {
    it: 'https://help.shippypro.com/it/knowledge/come-utilizzare-la-barra-di-ricerca-reattiva-di-shippypro',
    en: 'https://help.shippypro.com/en/knowledge/how-to-use-the-reactive-search-bar-in-shippypro',
    de: 'https://help.shippypro.com/en/knowledge/how-to-use-the-reactive-search-bar-in-shippypro',
    fr: 'https://help.shippypro.com/en/knowledge/how-to-use-the-reactive-search-bar-in-shippypro',
    es: 'https://help.shippypro.com/en/knowledge/how-to-use-the-reactive-search-bar-in-shippypro',
    pt: 'https://help.shippypro.com/en/knowledge/how-to-use-the-reactive-search-bar-in-shippypro',
    pl: 'https://help.shippypro.com/en/knowledge/how-to-use-the-reactive-search-bar-in-shippypro',
  },
  howWeCalculateKPIs: {
    it: 'https://help.shippypro.com/it/knowledge/come-calcoliamo-i-kpi',
    en: 'https://help.shippypro.com/en/knowledge/how-we-calculate-kpis',
    de: 'https://help.shippypro.com/en/knowledge/how-we-calculate-kpis',
    fr: 'https://help.shippypro.com/en/knowledge/how-we-calculate-kpis',
    es: 'https://help.shippypro.com/en/knowledge/how-we-calculate-kpis',
    pt: 'https://help.shippypro.com/en/knowledge/how-we-calculate-kpis',
    pl: 'https://help.shippypro.com/en/knowledge/how-we-calculate-kpis',
  },
  carrierRates: {
    it: 'https://help.shippypro.com/it/knowledge/come-impostare-le-tariffe-dei-corrieri',
    en: 'https://help.shippypro.com/en/knowledge/articles/3339351-how-to-set-carriers-rates',
    de: 'https://help.shippypro.com/en/knowledge/articles/3339351-how-to-set-carriers-rates',
    fr: 'https://help.shippypro.com/fr/knowledge/comment-ajouter-vos-tarifs-transporteurs',
    es: 'https://help.shippypro.com/es/knowledge/como-configurar-las-tarifas-de-tus-transportistas',
    pt: 'https://help.shippypro.com/en/knowledge/articles/3339351-how-to-set-carriers-rates',
    pl: 'https://help.shippypro.com/en/knowledge/articles/3339351-how-to-set-carriers-rates',
  },
  shipmentCostHelp: {
    it: 'https://help.shippypro.com/it/knowledge/come-calcoliamo-i-kpi#shipment-cost',
    en: 'https://help.shippypro.com/en/knowledge/how-we-calculate-kpis#shipment-cost',
    de: 'https://help.shippypro.com/en/knowledge/how-we-calculate-kpis#shipment-cost',
    fr: 'https://help.shippypro.com/en/knowledge/how-we-calculate-kpis#shipment-cost',
    es: 'https://help.shippypro.com/en/knowledge/how-we-calculate-kpis#shipment-cost',
    pt: 'https://help.shippypro.com/en/knowledge/how-we-calculate-kpis#shipment-cost',
    pl: 'https://help.shippypro.com/en/knowledge/how-we-calculate-kpis#shipment-cost',
  },
};
