import { FC } from 'react';
import classnames from 'classnames';

import { IDetailRowProps } from './types';

/**
 * A component that displays a list of events in chronological order.
 * Said events will have a name, a meta (usually time string) and some custom content.
 *
 * @interface IDetailRowProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const DetailRow: FC<IDetailRowProps> = props => {
  const {
    title,
    content,
    rightElement,
    titleClassName = '',
    contentClassName = '',
    rightElementClassName = '',
  } = props;

  const code = title.toLowerCase().replace(/ /g, '-');

  return (
    <div
      data-test={code + '-detail-row'}
      className="w-full flex justify-between items-center border-b-2"
    >
      <div className="flex flex-col p-2">
        <div
          data-test={code + 'title-detail-row'}
          className={classnames(titleClassName)}
        >
          {title}
        </div>
        <div
          data-test={code + 'content-detail-row'}
          className={classnames(contentClassName)}
        >
          {content}
        </div>
      </div>
      {rightElement && (
        <div
          data-test={code + 'right-element-detail-row'}
          className={classnames('flex pr-[1.5rem]', rightElementClassName)}
        >
          {rightElement}
        </div>
      )}
    </div>
  );
};
